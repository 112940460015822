import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="flex flex-col items-center justify-center h-screen">
    <h1 className="text-6xl">404: Not Found</h1>
    </div>
  </Layout>
)

export default NotFoundPage
